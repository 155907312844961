.pricing__section {
    background-image: url(./media/excavator.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricing__container-card {
    background: #181818;
    box-shadow: 0 6px 20px #202020;
    width: 280px;
    height: 500px;
    text-decoration: none;
    border-radius: 4px;
}

.pricing__container-card:nth-child(2) {
    margin: 0 24px;
}

.pricing__container-card:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
}

.pricing__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.pricing__heading {
    color: #f8f8f8;
    font-size: 22px;
    margin-bottom: 24px;
    align-self: center;
    text-transform: uppercase;
}

.pricing__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;

    align-items: center;
    color: #f8f8f8;
}

.pricing__container-cardInfo h3 {
    margin-bottom: 5px;
    font-size: 24px;
    text-align: center;
}

.pricing__container-cardInfo h4 {
    font-size: 16px;
    text-align: center;
    padding-top: 20px;
}

.pricing__container-cardInfo h5 {
    padding: 20px 0;
    font-size: 12px;
    text-align: center;
}

.pricing__container-cardInfo p {
    font-size: 12px;
    /* margin-bottom: 80px; */
    text-align: center;
}

.pricing__container-features {
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing__container-features p {
    font-weight: 300;
}

.pricing__container-features li {
    margin-bottom: 10px;
    text-align: center;
}

.icon {
    margin: 24px 0;
}

@media screen and (max-width: 960px) {
    .pricing__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing__container-card {
        width: 90%;
    }

    .pricing__container-card:nth-child(2) {
        margin: 20px 24px;
    }

    .pricing__wrapper {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing__container-card:hover {
        transform: none;
    }
}
