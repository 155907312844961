/* * {
    margin: 0;
    padding: 0;
} */

.contact {
    position: relative;
    min-height: 100vh;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(../../media/highway.jpg);
    background-size: cover;
    background-position: center;
}

.contact .content {
    max-width: 800px;
    text-align: center;
}

.contact .content h2 {
    font-size: 50px;
    font-weight: 600;
    color: #181818;
    text-transform: uppercase;
}

.contact .content p {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.contact .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.contact .container .contactInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.box {
    position: relative;
    padding: 20px 0;
    width: 400px;
    /* display: inline-block; */
}

.box .icons {
    float: left;
    vertical-align: bottom;
    padding: 0 15px;
}

.box .icons:hover {
    color: #3d3d3d;
    transform: scale(1.1);
}

.text h3 {
    /* padding: 5px 0; */
    color: #181818;
    font-size: 22px;
}

.text p {
    padding: 7px 0;
    color: #fff;
    font-weight: 600;
}

.text p:hover {
    color: #3d3d3d;
    transform: scale(1.01);
}

.hours {
    text-align: left;
}

.hours h2 {
    font-size: 22px;
    color: #181818;
}

.hours p {
    color: #fff;
    padding: 4px 0;
    font-weight: 600;
}

.hours p:hover {
    color: #181818;
}

@media screen and (max-width: 420px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .box {
        left: -100px;
    }
    .text p {
        font-size: 14px;
    }
}

@media screen and (max-width: 380px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .box {
        left: -100px;
    }
    .text p {
        font-size: 14px;
    }
}
