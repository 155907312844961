.home-banner {
    position: relative;
    width: 100%;
    min-height: 60vh; /*100 */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: url('../../media/Heli.jpg'); */
    background-size: cover;
    background-position: center;
}

.home-banner::after {
    content: '';
}

.home-banner .content {
    max-width: 900px;
    text-align: center;
    position: absolute;
    background-color: rgba(24, 24, 24, 0.8);

    padding: 10px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    animation: slide-down 5s;
    -webkit-animation: slide-down 5s;
    -moz-animation: slide-down 5s;
    -o-animation: slide-down 5s;
    -ms-animation: slide-down 5s;
}

@keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}
@-moz-keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@-webkit-keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@-o-keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@-ms-keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

.home-banner .content h1 {
    font-size: 2rem;
    color: #aaaaaa;
    font-weight: 700;
    text-transform: uppercase;
}

.home-banner .content p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #f8f8f8;
    padding: 10px 0;
}

/* Social Links */

.header-social-links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    color: #181818;
}

.header-social-links a {
    padding: 0 40px;
}

.header-social-links .fb:hover {
    transform: scale(1.2);
}
.header-social-links .tt:hover {
    transform: scale(1.2);
}
.header-social-links .insta:hover {
    transform: scale(1.2);
}
.header-social-links .yt:hover {
    transform: scale(1.2);
}

/* Slideshow */
.sliderimg {
    width: 100%;
    height: 80vh;
    object-fit: cover;
}

.alice-carousel__prev-btn {
    visibility: hidden;
}

.alice-carousel__next-btn {
    visibility: hidden;
}

.alice-carousel__dots-item {
    filter: invert(0.8);
}

@media screen and (max-width: 420px) {
    .content {
        width: 90%;
        top: 40% !important;
    }
    .content a {
        padding: 0 20px !important;
    }
}

@media screen and (max-width: 380px) {
    .content {
        width: 90%;
        top: 40% !important;
    }
    .content a {
        padding: 0 20px !important;
    }
}
