.navbar {
    background-color: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

.navbar-logo:hover {
    color: #aaaaaa;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.navbar-icon:hover {
    color: #aaaaaa;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}

.nav-item:hover {
    border-bottom: 2px solid #aaaaaa;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    text-transform: uppercase;
}

.nav-links:hover {
    color: #aaaaaa;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .navbar {
        background-color: #181818;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #181818;
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        color: #aaaaaa;
        transform: scale(1.2);
        transition: all 0.3s ease;
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        width: 100%;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(7%, 50%); /* 25% 50% */
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 1rem;
    }

    .nav-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
    }
}

@media screen and (max-width: 420px) {
    .navbar-container {
        left: 0;
        padding: 0 !important;
    }
    .navbar-logo {
        font-size: 1.5rem;
        transform: translate(5%, 50%);
    }
}

@media screen and (max-width: 380px) {
    .navbar-container {
        left: 0;
        padding: 0 !important;
    }
    .navbar-logo {
        font-size: 1.5rem;
        transform: translate(5%, 50%);
    }
}
