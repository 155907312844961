@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #181818;
}
::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 10px;
}

@media screen and (max-width: 380px) {
    html {
        width: 375px;
    }
}

/* * {
    margin: 0;
    padding: 0;
} */

.contact {
    position: relative;
    min-height: 100vh;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(/static/media/highway.f6684f91.jpg);
    background-size: cover;
    background-position: center;
}

.contact .content {
    max-width: 800px;
    text-align: center;
}

.contact .content h2 {
    font-size: 50px;
    font-weight: 600;
    color: #181818;
    text-transform: uppercase;
}

.contact .content p {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.contact .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.contact .container .contactInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.box {
    position: relative;
    padding: 20px 0;
    width: 400px;
    /* display: inline-block; */
}

.box .icons {
    float: left;
    vertical-align: bottom;
    padding: 0 15px;
}

.box .icons:hover {
    color: #3d3d3d;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.text h3 {
    /* padding: 5px 0; */
    color: #181818;
    font-size: 22px;
}

.text p {
    padding: 7px 0;
    color: #fff;
    font-weight: 600;
}

.text p:hover {
    color: #3d3d3d;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}

.hours {
    text-align: left;
}

.hours h2 {
    font-size: 22px;
    color: #181818;
}

.hours p {
    color: #fff;
    padding: 4px 0;
    font-weight: 600;
}

.hours p:hover {
    color: #181818;
}

@media screen and (max-width: 420px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .box {
        left: -100px;
    }
    .text p {
        font-size: 14px;
    }
}

@media screen and (max-width: 380px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .box {
        left: -100px;
    }
    .text p {
        font-size: 14px;
    }
}

:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--mobile {
    text-align: center;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
}

.btn--wide {
    padding: 12px 64px;
    font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
    transition: all 0.3s ease-out;
    background: #aaaaaa;
    color: #fff;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.btn--wide:hover {
    color: #fff;
    background-color: #f00946;
    transition: all 0.2s ease-out;
}

.blue {
    background-color: #276afb;
    color: #fff;
    border: none;
}

.red {
    background-color: #f00946;
    color: #fff;
    border: none;
}

.primary {
    background-color: #242424;
    color: #fff;
    border: none;
}

.primary:hover {
    background-color: #fff;
    color: #fff;
    border: none;
}

.green {
    background-color: #25ce4a;
    color: #fff;
    border: none;
}

.green:hover {
    background-color: #242424;
}

.btn-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
}

.navbar {
    background-color: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
}

.container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

.navbar-logo:hover {
    color: #aaaaaa;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.navbar-icon:hover {
    color: #aaaaaa;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

.nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
}

.nav-item:hover {
    border-bottom: 2px solid #aaaaaa;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    text-transform: uppercase;
}

.nav-links:hover {
    color: #aaaaaa;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .navbar {
        background-color: #181818;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #181818;
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        color: #aaaaaa;
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        transition: all 0.3s ease;
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        width: 100%;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: translate(7%, 50%);
                transform: translate(7%, 50%); /* 25% 50% */
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 1rem;
    }

    .nav-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
    }
}

@media screen and (max-width: 420px) {
    .navbar-container {
        left: 0;
        padding: 0 !important;
    }
    .navbar-logo {
        font-size: 1.5rem;
        -webkit-transform: translate(5%, 50%);
                transform: translate(5%, 50%);
    }
}

@media screen and (max-width: 380px) {
    .navbar-container {
        left: 0;
        padding: 0 !important;
    }
    .navbar-logo {
        font-size: 1.5rem;
        -webkit-transform: translate(5%, 50%);
                transform: translate(5%, 50%);
    }
}

.home__hero-section {
    color: #fff;
    padding: 160px 0;
}

.home__hero-row {
    align-items: center;
}

.row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
}

.col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1 1;
    max-width: 50%;
    flex-basis: 50%;
}

.home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;

    animation: slide-right 5s;
    -webkit-animation: slide-right 5s;
    -moz-animation: slide-right 5s;
    -o-animation: slide-right 5s;
    -ms-animation: slide-right 5s;
}

@-webkit-keyframes slide-right {
    from {
        margin-left: -100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes slide-right {
    from {
        margin-left: -100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.top-line {
    color: #f8f8f8;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.heading {
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 600;
    color: #aaaaaa;
}

.dark {
    color: #181818;
}

.darkBg {
    background-color: #181818;
}

.home__hero-subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    margin-right: 7px;
    font-size: 18px;
    line-height: 28px;

    color: #f8f8f8;
}

.home__hero-img-wrapper {
    max-width: 555px;
}

.home__hero-img {
    max-width: 96%;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0;
    margin-right: 0;
    margin-left: 10px;
    padding-right: 0;
    /* margin: 0 0 0 10px; */
}

.home__hero-img-wrapper img {
    border: 0;
    max-width: 100%;
    max-height: 400px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 5px;

    animation: slide-left 5s;
    -webkit-animation: slide-left 5s;
    -moz-animation: slide-left 5s;
    -o-animation: slide-left 5s;
    -ms-animation: slide-left 5s;
}

@keyframes slide-left {
    from {
        margin-left: 90%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
@-webkit-keyframes slide-left {
    from {
        margin-left: 90%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .container {
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
        padding-bottom: 65px;
    }

    .col {
        max-width: 100%;
        flex-basis: 100%;
    }
}

.about-banner {
    position: relative;
    width: 100%;
    min-height: 60vh; /*100 */
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(/static/media/PVS.60bc8c9d.jpg);
    background-size: cover;
    background-position: center;
}

.about-banner::after {
    content: '';
}

.about-banner .we-content {
    /* max-width: 900px; */
    text-align: center;
    padding: 10px;
    margin-right: 5px;
    position: absolute;
    top: 1%;
    left: 50%;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(24, 24, 24, 0.8);
    border-radius: 5px;
}

.about-banner .we-content h1 {
    color: #aaaaaa;
    font-size: 1.75rem;
    padding: 5px 0;
}

.about-banner .we-content p {
    font-size: 1rem;
    font-weight: 600;
    color: #f8f8f8;
}

@media screen and (max-width: 420px) {
    .about-banner {
        background-position: left;
    }
    .we-content {
        width: 100%;
        left: 0 !important;
        padding: 15px !important;
    }
    .about-banner .we-content h1 {
        font-size: 1.5rem;
    }
    .about-banner .we-content p {
        font-size: 14px;
    }
}

@media screen and (max-width: 380px) {
    .about-banner {
        background-position: left;
    }
    .we-content {
        width: 100%;
        left: 0 !important;
        padding: 15px !important;
    }
    .about-banner .we-content h1 {
        font-size: 1.5rem;
    }
    .about-banner .we-content p {
        font-size: 14px;
    }
}

.home-banner {
    position: relative;
    width: 100%;
    min-height: 60vh; /*100 */
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: url('../../media/Heli.jpg'); */
    background-size: cover;
    background-position: center;
}

.home-banner::after {
    content: '';
}

.home-banner .content {
    max-width: 900px;
    text-align: center;
    position: absolute;
    background-color: rgba(24, 24, 24, 0.8);

    padding: 10px;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    animation: slide-down 5s;
    -webkit-animation: slide-down 5s;
    -moz-animation: slide-down 5s;
    -o-animation: slide-down 5s;
    -ms-animation: slide-down 5s;
}

@keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@-webkit-keyframes slide-down {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

.home-banner .content h1 {
    font-size: 2rem;
    color: #aaaaaa;
    font-weight: 700;
    text-transform: uppercase;
}

.home-banner .content p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #f8f8f8;
    padding: 10px 0;
}

/* Social Links */

.header-social-links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    color: #181818;
}

.header-social-links a {
    padding: 0 40px;
}

.header-social-links .fb:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.header-social-links .tt:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.header-social-links .insta:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.header-social-links .yt:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

/* Slideshow */
.sliderimg {
    width: 100%;
    height: 80vh;
    object-fit: cover;
}

.alice-carousel__prev-btn {
    visibility: hidden;
}

.alice-carousel__next-btn {
    visibility: hidden;
}

.alice-carousel__dots-item {
    -webkit-filter: invert(0.8);
            filter: invert(0.8);
}

@media screen and (max-width: 420px) {
    .content {
        width: 90%;
        top: 40% !important;
    }
    .content a {
        padding: 0 20px !important;
    }
}

@media screen and (max-width: 380px) {
    .content {
        width: 90%;
        top: 40% !important;
    }
    .content a {
        padding: 0 20px !important;
    }
}

.pricing__section {
    background-image: url(/static/media/excavator.6cfd48d1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 100px 0 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pricing__container-card {
    background: #181818;
    box-shadow: 0 6px 20px #202020;
    width: 280px;
    height: 500px;
    text-decoration: none;
    border-radius: 4px;
}

.pricing__container-card:nth-child(2) {
    margin: 0 24px;
}

.pricing__container-card:hover {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
}

.pricing__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
}

.pricing__heading {
    color: #f8f8f8;
    font-size: 22px;
    margin-bottom: 24px;
    align-self: center;
    text-transform: uppercase;
}

.pricing__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 24px;

    align-items: center;
    color: #f8f8f8;
}

.pricing__container-cardInfo h3 {
    margin-bottom: 5px;
    font-size: 24px;
    text-align: center;
}

.pricing__container-cardInfo h4 {
    font-size: 16px;
    text-align: center;
    padding-top: 20px;
}

.pricing__container-cardInfo h5 {
    padding: 20px 0;
    font-size: 12px;
    text-align: center;
}

.pricing__container-cardInfo p {
    font-size: 12px;
    /* margin-bottom: 80px; */
    text-align: center;
}

.pricing__container-features {
    margin: 16px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pricing__container-features p {
    font-weight: 300;
}

.pricing__container-features li {
    margin-bottom: 10px;
    text-align: center;
}

.icon {
    margin: 24px 0;
}

@media screen and (max-width: 960px) {
    .pricing__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing__container-card {
        width: 90%;
    }

    .pricing__container-card:nth-child(2) {
        margin: 20px 24px;
    }

    .pricing__wrapper {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing__container-card:hover {
        -webkit-transform: none;
                transform: none;
    }
}

.loads {
    background-color: #181818;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.header h1 {
    color: #aaaaaa;
    font-size: 28px;
    font-weight: 700;
    padding: 40px 0;
    letter-spacing: 1.4px;
    text-transform: uppercase;

    text-align: center;
}

.header span {
    font-size: 2.2rem;
    color: #f8f8f8;
}

img {
    height: 200px;
    width: 100%;
}

.loads p {
    padding-top: 10px;
}

/* @media screen and (min-width: 380px) {
    .loads {
        margin-right: 10px;
    }
} */

.footer {
    padding-bottom: 0px;
}
.footer .socialMedia {
    display: contents;
    text-align: center;
    flex-direction: row;
    padding: 10px 10px;
}

.footer .footer-social-links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 30px 0;
}

.footer-social-links .fb:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.footer-social-links .tt:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.footer-social-links .insta:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.footer-social-links .yt:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.pong a {
    color: #aaaaaa;
    font-weight: 700;
    text-decoration: none;
    padding: 50px 40px;
}
.pong a:hover {
    color: #f8f8f8;
}
.HaydenTrans {
    padding-top: 20px;
}
.HaydenTrans a {
    color: #aaaaaa;
    font-weight: 700;
    text-decoration: none;
    padding: 50px 40px;
    flex-direction: column;
}
.HaydenTrans a:hover {
    color: #f8f8f8;
}
.copyrightText {
    padding-bottom: 20px;
    padding-top: 20px;

    text-align: center;
}

.copyrightText p {
    color: #f8f8f8;
}

.copyrightText span {
    color: #aaaaaa;
    font-weight: 700;
    text-decoration: none;
}

