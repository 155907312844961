@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    background: #181818;
}
::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 10px;
}

@media screen and (max-width: 380px) {
    html {
        width: 375px;
    }
}
