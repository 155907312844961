.loads {
    background-color: #181818;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.header h1 {
    color: #aaaaaa;
    font-size: 28px;
    font-weight: 700;
    padding: 40px 0;
    letter-spacing: 1.4px;
    text-transform: uppercase;

    text-align: center;
}

.header span {
    font-size: 2.2rem;
    color: #f8f8f8;
}

img {
    height: 200px;
    width: 100%;
}

.loads p {
    padding-top: 10px;
}

/* @media screen and (min-width: 380px) {
    .loads {
        margin-right: 10px;
    }
} */
